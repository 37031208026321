import * as React from 'react';
import { Link } from 'gatsby';
import { Flex, SimpleGrid, Image } from '@chakra-ui/react';

import '../styles/crop-data.scss';

const CROPS = [
  {
    label: 'Corn',
    href: '/crop-data/corn',
  },
  {
    label: 'Soybean',
    href: '/crop-data/soybean',
  },
  {
    label: 'Spring Wheat',
    href: '/crop-data/spring-wheat',
  },
  {
    label: 'Winter Wheat',
    href: '/crop-data/winter-wheat',
  },
  {
    label: 'Potato',
    href: '/crop-data/potato',
  },
  {
    label: 'Canola',
    href: '/crop-data/canola',
  },
  {
    label: 'Alfalfa',
    href: '/crop-data/alfalfa',
  },
  {
    label: 'Peas',
    href: '/crop-data/peas',
  },
  {
    label: 'Sugar Beets',
    href: '/crop-data/sugar-beets',
  },
];
const CropDataGrid = () => {
  return (
    <SimpleGrid
      columns={{ sm: 1, md: 3, lg: 3 }}
      spacing={{ md: 10, lg: 20 }}
      className="crop-data-grid"
    >
      {CROPS.map((crop) => (
        <Link to={crop.href} key={crop.label}>
          <Flex flexDirection="column" className="grid">
            <Image
              src={`${crop.href}.png`}
              alt="Crop Data img"
              className="gridimage"
            />
            <Image
              src="/crop-data/crop-data-overlay.png"
              alt="Crop Data Overlay img"
              className="gridimage-overlay"
            />
            <p className="label">{crop.label}</p>
          </Flex>
        </Link>
      ))}
    </SimpleGrid>
  );
};

export default CropDataGrid;
