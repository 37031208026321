import * as React from 'react';
import { Box, Container, Stack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CropDataGrid from '../components/crop-data-grid';

import '../styles/crop-data.scss';

const CropData = () => {
  return (
    <Layout>
      <SEO
        title="Crop Data and Agronomy Research About Crystal Green Fertilizer | Ostara"
        description="See the agronomy research behind Crystal Green's higher yields and greater nutrient uptake so you can make data-driven decisions that enhance your profit potential. "
        keywords="Agronomy Research, Crop Data"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Crop Data for Crystal Green Fertilizers</h1>
                  <p>
                    See how our Crystal Green Fertilizers make measurable
                    impacts on yield, plant health and your bottom line
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/CG_Ag_potato-starter-crop-edited.jpg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={{ lg: 4 }} className="crop-data-body-container">
          <Container
            maxW={1400}
            marginTop={100}
            paddingRight={{ sm: 10, lg: 14 }}
          >
            <Box>
              <h3 className="crop-data-heading">
                Crystal Green Fertilizer Agronomy Research
              </h3>
              <h3 className="crop-data-subheading">
                What type of crop are you looking to enhance?
              </h3>
            </Box>
            <CropDataGrid />
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default CropData;
